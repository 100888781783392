import PropTypes from 'prop-types';
import React from 'react';

import Footer from './footer';
import Header from './header';

const Framework = ({ children }) => {
	return (
		<>
			<Header />
			{children}
			<Footer />
		</>
	);
};

Framework.propTypes = {
	children: PropTypes.array,
};

export default Framework;
