import React from 'react';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';

const IndexPage = () => {
	return (
		<Framework>
			<Seo title="Flinto.se" />
			<div className="container-fluid mt-4">
				<h1 className="h4">Save the frill, skaffa flinto idag!</h1>
			</div>
		</Framework>
	);
};

export default IndexPage;
