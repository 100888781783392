import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ children, ...options }) => {
	if (
		options.to.substring(0, 4) === 'http' ||
		options.to.indexOf('.pdf') !== -1
	) {
		options.href = options.to;
		options.to = undefined;
		return <a {...options}>{children}</a>;
	}
	return <GatsbyLink {...options}>{children}</GatsbyLink>;
};

Link.propTypes = {
	children: PropTypes.any,
};

export default Link;
