import React from 'react';

import Link from './link';

import logo from '../../images/framework/logo.svg';

const Header = () => {
	return (
		<>
			<div className="container-fluid">
				<Link className="brand me-auto" to="/">
					<div className="pt-3 mt-xl-3">
						<img alt="Flinto logo" className="d-inline" src={logo} />
					</div>
				</Link>
			</div>
		</>
	);
};

export default Header;
