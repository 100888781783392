/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function Seo({ description, tags, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						description
						siteUrl
						title
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
			<title>{title}</title>
			<meta content={metaDescription} name="description" />
			<meta content={tags} name="keywords" />

			<meta content={metaDescription} property="og:description" />
			<meta content={site.siteMetadata.title} property="og:site_name" />
			<meta content={title} property="og:title" />
			<meta content="website" property="og:type" />

			<meta content="summary" name="twitter:card" />
			<meta content={site.siteMetadata.author} name="twitter:creator" />
			<meta content={metaDescription} name="twitter:description" />
			<meta content={title} name="twitter:title" />
		</Helmet>
	);
}

Seo.defaultProps = {
	description: '',
};

Seo.propTypes = {
	description: PropTypes.string,
	tags: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default Seo;
